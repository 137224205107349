/* eslint-disable no-unused-vars */
import axios from "axios";
import React from "react";

const BASE_URL = "https://api.sujata.zicorp.co.in/api"; 
// const BASE_URL = "http://localhost:7007/api";


const apiReference = axios.create({
  baseURL: BASE_URL,
});


apiReference.interceptors.request.use(
  (config) => {
    
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


const api = {
  GetQRPrint: async () => {
    try {
      const response = await apiReference.get(`${BASE_URL}/PrintQR`, {});
      return response.data;
    } catch (error) {
      throw new Error(`Error getting User: ${error.message}`);
    }
  },
  PostDeletePrintQR: async (deleteData) => {
    try {
      const response = await apiReference.post(
        `${BASE_URL}/PrintQR/DELETEQR`,
        deleteData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(`Request failed with status ${error.response.status}`);
      } else if (error.request) {
        throw new Error("No response received from the server");
      } else {
        throw new Error(`Request failed: ${error.message}`);
      }
    }
  },
};

export default api;
