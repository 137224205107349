import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { QRCodeCanvas } from "qrcode.react";
import api from "./api";

const PrintQRCode = () => {
  const printContainerRef = useRef();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const printRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.GetQRPrint();
        setSerialNumbers(data.map((item) => item.serialno));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: async () => {
      try {
        const deleteData = {
          print: serialNumbers.map((serialno) => ({ serialno })),
        };
        await api.PostDeletePrintQR(deleteData);
        window.location.reload();
        console.log("QR deleted successfully after printing");
      } catch (error) {
        console.error("Error while deleting QR after printing:", error);
      }
    },
  });

  const chunkArray = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
      arr.slice(i * size, i * size + size)
    );
  };

  const qrRows = chunkArray(
    serialNumbers.flatMap((sn) => [sn, sn, sn, sn]),
    4
  );

  return (
    <>
      <button onClick={handlePrint}>Print QR Code</button>

      <div ref={printRef}>
        <div ref={printContainerRef}>
          {qrRows.map((row, rowIndex) => (
            <div
              key={rowIndex}
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                padding: "2mm",
              }}
            >
              {row.map((serialNumber, index) => (
                <div
                  key={index}
                  style={{
                    width: "25%",
                    height: "auto",
                    paddingTop:10 ,
                    paddingLeft: 20,
                  }}
                >
                  <QRCodeCanvas value={serialNumber} size={40} level="H" />
                  <div style={{ fontSize: 8 }}>{serialNumber}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PrintQRCode;
