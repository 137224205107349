// src/App.js
import React from 'react';

import PrintQRCode from './PrintQRCode';

function App() {
  return (
    <div className="App">
      <h1>QR Label</h1>
      {/* <PrintBarcode /> */}
      <PrintQRCode />
    </div>
  );
}

export default App;
